import React, { useState } from "react";
import {
  auth,
  googleProvider,
  twitterProvider,
  linkedinProvider,
  microsoftProvider,
  db,
  doc,
  setDoc,
} from "./services/firebaseServices"; // Asume que esta es la ruta correcta
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

import Swal from "sweetalert2";
import logo from "./logo.svg";
import "./App.css";
import googleIcon from "./assets/images/google-8.png";
import twitterIcon from "./assets/images/twittwr-8.png";
import linkedinIcon from "./assets/images/linkend-8.png";
import microsoftIcon from "./assets/images/microsoft-8.png";

function App() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [lastName, setLastName] = useState("");
  const [documentNumber, setDocumentNumber] = useState("");
  const [diagnosis, setDiagnosis] = useState(""); // Cambiado de medicalSpeciality a diagnosis
  const [studentStatus, setStudentStatus] = useState(true);
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPatient, setIsPatient] = useState(""); // Cambiado de isSponsored a isPatient
  const [patientType, setPatientType] = useState(""); // Cambiado de sponsored a patientType
  const [errors, setErrors] = useState({});
  const [city, setCity] = useState("");  // Ciudad
  const [confirmAttendance, setConfirmAttendance] = useState(false);  // Confirmar asistencia

  const [customDiagnosis, setCustomDiagnosis] = useState(""); // Cambiado de customSpeciality a customDiagnosis
  const [customPatientType, setCustomPatientType] = useState(""); // Cambiado de customLab a customPatientType
  const [loading, setLoading] = useState(false);
  const patientTypeToSend =
    isPatient === "Otro" ? customPatientType : isPatient; // Actualizado de labToSend
  const diagnosisToSend = diagnosis === "Otro" ? customDiagnosis : diagnosis; // Actualizado de specialityToSend

  const validateForm = () => {
    let tempErrors = {};

    if (!fullName) tempErrors.fullName = "El nombre completo es requerido.";
    if (!lastName) tempErrors.lastName = "El apellido es requerido.";
    if (!city) tempErrors.city = "La ciudad es requerida.";
    if (!documentNumber)
      tempErrors.documentNumber = "El número de documento es requerido.";
    if (!diagnosis)
      // Cambiado de medicalSpeciality a diagnosis
      tempErrors.diagnosis = "El diagnóstico es requerido."; // Cambiado el mensaje de error
    if (!address) tempErrors.address = "La dirección es requerida.";
    if (!email) tempErrors.email = "El email es requerido.";
    if (!phoneNumber)
      tempErrors.phoneNumber = "El número de teléfono es requerido.";
    if (!password) tempErrors.password = "La contraseña es requerida.";
    if (password !== confirmPassword)
      tempErrors.confirmPassword = "Las contraseñas no coinciden.";

    setErrors(tempErrors);
    return tempErrors;
  };

  const handleBlur = (field) => {
    let tempErrors = { ...errors };
    if (!field) {
      tempErrors[field] = `El campo ${field} es obligatorio.`;
    } else {
      delete tempErrors[field];
    }
    setErrors(tempErrors);
  };

  const arePasswordsEqual = () => {
    return password === confirmPassword;
  };
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    if (!arePasswordsEqual()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "Las contraseñas no coinciden.",
      }));
    } else {
      setErrors((prevErrors) => {
        const { confirmPassword, ...rest } = prevErrors;
        return rest;
      });
    }
  };
  const saveUserProfile = async (uid) => {
    const userRef = doc(db, "patientsProfiles", uid);

    const userProfile = {
      fullName,
      lastName,
      documentNumber,
      diagnosis: diagnosisToSend,
      studentStatus,
      address,
      phoneNumber,
      email,
      city,  // Guardar ciudad
      confirmAttendance,  // Guardar confirmación de asistencia
      isPatient: patientType === "Si" ? patientTypeToSend : "No es paciente", // Actualizado de isSponsored
      patientType: patientType === "Si" ? patientTypeToSend : "No especificado", // Actualizado de lab
    };

    await setDoc(userRef, userProfile);
  };

  const handleRegister = async () => {
    setLoading(true);
    const formErrors = validateForm();

    if (!arePasswordsEqual()) {
      Swal.fire("Error", "Las contraseñas no coinciden.", "error");
      setLoading(false);
      return;
    }

    if (Object.keys(formErrors).length === 0) {
      try {
        // Autenticar al usuario con Firebase Authentication
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        const uid = userCredential.user.uid;

        // Aquí, el usuario ya está autenticado. Ahora, puedes guardar los detalles del usuario en Firestore.
        await saveUserProfile(uid);

        // Llamar a la función para enviar el correo
        const emailData = {
          email,
          password,
          fullName,
          lastName,
          documentNumber,
          diagnosis: diagnosisToSend, // Cambiado de medicalSpeciality a diagnosis y specialityToSend a diagnosisToSend
          studentStatus,
          address,
          phoneNumber,
          isPatient: patientTypeToSend, // Actualizado de isSponsored a isPatient y labToSend a patientTypeToSend
          patientType: patientTypeToSend, // Actualizado de lab a patientType y labToSend a patientTypeToSend
        };
        await fetch(
          "https://us-central1-artetechventures-623d5.cloudfunctions.net/sendEmailPatientes",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(emailData),
          }
        );

        // Mostrar un mensaje de éxito
        Swal.fire(
          "¡Éxito!",
          "Registro exitoso. Revisa tu correo para más detalles.",
          "success"
        );
        resetForm();
      } catch (error) {
        // Mostrar un mensaje de error
        Swal.fire("Error", "Error al registrarse: " + error.message, "error");
      }
    }
    setLoading(false);
  };

  const resetForm = () => {
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setFullName("");
    setLastName("");
    setDocumentNumber("");
    setDiagnosis(""); // Cambiado de setMedicalSpeciality a setDiagnosis
    setAddress("");
    setPhoneNumber("");
    setIsPatient(""); // Cambiado de setIsSponsored a setIsPatient
    setPatientType(""); // Cambiado de setSponsored a setPatientType
    setCustomDiagnosis(""); // Cambiado de setCustomSpeciality a setCustomDiagnosis
    setCustomPatientType(""); // Cambiado de setCustomLab a setCustomPatientType
    setCity("");  // Reiniciar ciudad
    setConfirmAttendance(false);  
  };

  return (
    <div className="App">
      <header className="App-header">
        {loading ? (
          <p>Creando su registro por favor, espere...</p>
        ) : (
          <>
            <img
              src="https://i.ibb.co/d7gGgNx/Recurso-1-8.png"
              className="App-logo"
              alt="logo"
            />
            <h2>Registro Día Mundial de la artritis</h2>

            <input
              type="text"
              placeholder="Nombre completo"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              onBlur={() => handleBlur("fullName")}
            />
            {errors.fullName && (
              <span style={{ color: "red" }}>{errors.fullName}</span>
            )}

            <input
              type="text"
              placeholder="Apellido"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              onBlur={() => handleBlur("lastName")}
            />
            {errors.lastName && (
              <span style={{ color: "red" }}>{errors.lastName}</span>
            )}

            <input
              type="text"
              placeholder="Número de documento"
              value={documentNumber}
              onChange={(e) => setDocumentNumber(e.target.value)}
              onBlur={() => handleBlur("documentNumber")}
            />
            {errors.documentNumber && (
              <span style={{ color: "red" }}>{errors.documentNumber}</span>
            )}

            <select
              value={diagnosis}
              onChange={(e) => setDiagnosis(e.target.value)}
              onBlur={() => handleBlur("diagnosis")}
            >
              <option value="">Selecciona un diagnóstico</option>
              <option value="Artritis reumatoide">Artritis reumatoide</option>
              <option value="Lupus eritematoso sistémico">
                Lupus eritematoso sistémico
              </option>
              <option value="Espondilitis anquilosante">
                Espondilitis anquilosante
              </option>
              <option value="Artritis psoriásica">Artritis psoriásica</option>
              <option value="Osteoartritis">Osteoartritis</option>
              <option value="Fibromialgia">Fibromialgia</option>
              <option value="Gota">Gota</option>
              <option value="Síndrome de Sjögren">Síndrome de Sjögren</option>
              <option value="Vasculitis">Vasculitis</option>
              <option value="Polimialgia reumática">
                Polimialgia reumática
              </option>
              <option value="Artritis juvenil idiopática">
                Artritis juvenil idiopática
              </option>
              <option value="Esclerodermia">Esclerodermia</option>
              <option value="Polimiositis y dermatomiositis">
                Polimiositis y dermatomiositis
              </option>
              <option value="Otro">Otro</option>
            </select>

            {diagnosis === "Otro" && (
              <input
                type="text"
                placeholder="Escribe tu diagnóstico"
                value={customDiagnosis}
                onChange={(e) => setCustomDiagnosis(e.target.value)}
              />
            )}
            {errors.diagnosis && (
              <span style={{ color: "red" }}>{errors.diagnosis}</span>
            )}

            <input
              type="text"
              placeholder="Dirección"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              onBlur={() => handleBlur("address")}
            />
            {errors.address && (
              <span style={{ color: "red" }}>{errors.address}</span>
            )}
   {/* Campo Ciudad */}
   <input
          type="text"
          placeholder="Ciudad"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          onBlur={() => handleBlur("city")}
        />
        {errors.city && <span style={{ color: "red" }}>{errors.city}</span>}

        {/* Campo Confirmar Asistencia */}
        <div>
          <label>
            <input 
              type="checkbox" 
              checked={confirmAttendance} 
              onChange={(e) => setConfirmAttendance(e.target.checked)} 
            />
            Confirmar asistencia presencial
          </label>
        </div>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onBlur={() => handleBlur("email")}
            />
            {errors.email && (
              <span style={{ color: "red" }}>{errors.email}</span>
            )}

            <input
              type="text"
              placeholder="Número de teléfono"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              onBlur={() => handleBlur("phoneNumber")}
            />
            {errors.phoneNumber && (
              <span style={{ color: "red" }}>{errors.phoneNumber}</span>
            )}
            <select
              value={patientType}
              onChange={(e) => setPatientType(e.target.value)}
              onBlur={() => handleBlur("patientType")}
            >
              <option value="">¿Eres paciente?</option>
              <option value="Si">Sí</option>
              <option value="No">No</option>
            </select>

            {patientType === "No" && (
              <>
                <select
                  value={isPatient}
                  onChange={(e) => setIsPatient(e.target.value)}
                  onBlur={() => handleBlur("isPatient")}
                >
                  <option value="">Selecciona un tipo</option>
                  <option value="Cuidador">Cuidador</option>
                  <option value="Enfermero">Enfermero</option>
                  <option value="Familiar de paciente">
                    Familiar de paciente
                  </option>

                  <option value="Otro">Otro</option>
                </select>
                {isPatient === "Otro" && (
                  <input
                    type="text"
                    placeholder="Escribe el tipo de paciente"
                    value={customPatientType}
                    onChange={(e) => setCustomPatientType(e.target.value)}
                  />
                )}
                {errors.isPatient && (
                  <span style={{ color: "red" }}>{errors.isPatient}</span>
                )}
              </>
            )}

            <hr />

            <input
              type="password"
              placeholder="Contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {errors.password && (
              <span style={{ color: "red" }}>{errors.password}</span>
            )}
            <input type="hidden" value={studentStatus} />

            <input
              type="password"
              placeholder="Confirmar Contraseña"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              // onChange={handleConfirmPasswordChange}
            />
            {errors.confirmPassword && (
              <span style={{ color: "red" }}>{errors.confirmPassword}</span>
            )}

            <button
              onClick={handleRegister}
              disabled={Object.keys(errors).length > 0}
            >
              Regístrate
            </button>
          </>
        )}
      </header>
    </div>
  );
}

export default App;
